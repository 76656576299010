<script>
import Layout from "../../../layouts/main";
import payChannel from './pay-channels.vue';
import checkoutPreview from './checkout-preview.vue';
import SmartRouting from "./smart-routing.vue";
import payoutChannel from "../payout-channel/pay-channels.vue";
import reconChannel from './pay-channels.vue';
export default {
    components: {
        Layout,
        checkoutPreview,
        payChannel,
        SmartRouting,
        payoutChannel,
        reconChannel
    },
    data() {
        return {
            activeTab: "Payin Channels",
            tabs: [],
            isPreviewOpen:false,
        }
    },
    computed: {
        isMasterMerchant() {
            return this.$store.state.auth.currentUser.is_master_merchant;
        },
        userRoles() {
            return this.$store.getters["auth/getUserRolesList"];
        }
    },
    watch: {
        '$route.query.tab'() {
            if(this.$route.query.tab && this.tabs.includes(this.$route.query.tab)) {
                this.activeTab = this.$route.query.tab;
            }
        },
    },
    created() {
        // if(!this.isMasterMerchant) {
        //     this.tabs.splice(3, 0, "Notifications");
        //     this.tabs.push('Advanced');
        // }
        this.isPreviewOpen = this.$route.query.open === "checkout-preview"
        
        if((this.userRoles.includes('admin') || this.userRoles.includes('sub-admin') || this.userRoles.includes('payment-channel-configurator') || this.userRoles.includes('payment-admin')) && !this.isPreviewOpen) {
            this.tabs.push("Payin Channels");
        }

        if((this.userRoles.includes('admin') || this.userRoles.includes('sub-admin') || this.userRoles.includes('payout-channel-configurator') || this.userRoles.includes('payouts-admin'))) {
             this.tabs.push('Payout Channels')
        }

        if((this.userRoles.includes('admin') || this.userRoles.includes('sub-admin') || this.userRoles.includes('recon-admin') || this.userRoles.includes('recon-configurator'))) {
             this.tabs.push('Recon Channels')
        }

        if(this.userRoles.includes('admin') || this.userRoles.includes('sub-admin') || this.userRoles.includes('payment-channel-configurator')) {
            this.tabs.push("Checkout Preview");
        }

        if((this.userRoles.includes('admin') || this.userRoles.includes('sub-admin') || this.userRoles.includes('payment-channel-configurator') || this.userRoles.includes('payment-admin')) && !this.isPreviewOpen && !this.isMasterMerchant) {
            this.tabs.push("Routing");
        }
        this.activeTab = this.tabs[0]

        //  <b-btn variant="primary"  @click="tabClick('checkout_preview')" class="tab-button" :class="{'tab-active': activeTab === 'checkout_preview'}"
        //   v-if="userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payout-channel-configurator')">
        //   {{ $t('views.payments.transactions.checkout_preview') }}
        // </b-btn>

        //         <b-btn variant="primary"  @click="tabClick('routing')" class="tab-button" :class="{'tab-active': activeTab === 'routing'}"
        //   v-if="(userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payment-channel-configurator') || userRoles.includes('payment-admin')) && !isPreviewOpen && !isMasterMerchant">
        //   Routing
        // </b-btn>

        // <b-btn variant="primary" class="tab-button" :class="{'tab-active': activeTab === 'payment_channels'}"
        //       v-if="(userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payment-channel-configurator') || userRoles.includes('payment-admin') || userRoles.includes('recon-admin') || userRoles.includes('recon-configurator')) && !this.isPreviewOpen">
        //       {{ $t('views.payments.transactions.payment_channels') }}
        //     </b-btn>

        if(this.$route.query.tab && this.tabs.includes(this.$route.query.tab)) {
            this.activeTab = this.$route.query.tab;
        }
    },
    methods: {
        tabClicked(tab) {
            this.activeTab = tab;
            this.$router.push({name: 'Payment Channels', query: {tab: tab}});
        }
    }
}
</script>

<template>
    <Layout>
        <div class="menu-content">
            <div class="tab-content-section">
                <b-btn variant="primary" v-for="(tab, index) in tabs" :key="index" @click="tabClicked(tab)" class="tab-button" :class="{'tab-active': activeTab === tab}">{{ tab }}</b-btn>
            </div>
        </div>
        <payChannel v-if="activeTab === 'Payin Channels'" />
        <checkoutPreview v-else-if="activeTab === 'Checkout Preview'" />
        <SmartRouting v-else-if=" activeTab === 'Routing'" />
        <payoutChannel v-else-if=" activeTab === 'Payout Channels'" />
        <reconChannel v-if="activeTab === 'Recon Channels'" isForReconChannels />
    </Layout>
</template>

<style>

</style>